<template>
  <div class="photography">
    <MainLayout>
      <template v-slot:content>
        <section>
          <h2>(Drone) fotografie.</h2>

          <p>
            Ben je op zoek naar een luchtfoto of drone video van jouw bedrijf of
            van een specifieke locatie? Met een drone kunnen we unieke beelden
            maken!<br /><br />

            Met foto's aan de grond helpen we je natuurlijk ook graag.
            Bijvoorbeeld van een product of een ander onderwerp naar keuze.
            <br /><br />

            Benieuwd wat we al hebben gedaan?
            <br />
            Check ons portfolio met de tag
            <span class="yellow">fotografie</span>.
          </p>

          <router-link class="btn" to="/portfolio"
            >Bekijk ons portfolio</router-link
          >
        </section>
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/MainLayout.vue";

export default {
  name: "Photography",
  components: {
    MainLayout,
  },
};
</script>